<template>
    <div class="c-loader" :class="loader == true ? 'c-loader--visible' : ''">
        <div class="c-loader__header">{{text.loaderTitle}}</div>
        <div class="c-loader__text">{{text.loaderIntro}}</div>
        <div class="c-loader__bar"></div>
        <div class="c-loader__image"></div>
    </div>
    <div class="c-container" :class="loader == false ? 'c-container--visible' : ''" v-if="reset == false">
        <div class="c-hero-img"></div>
        <main class="c-main">
            <div class="c-header" :class="activePage != 'questions' ? 'c-header--flex-end' : ''" v-if="loader == false">
                <button
                    class="c-header__back"
                    :class="questionActive == 0 ? 'c-header__back--disabled' : ''"
                    :disabled="questionActive == 0"
                    @click="goBack();"
                    v-if="activePage == 'questions'">
                    {{text.back}}
                </button>
                <button class="c-header__close" @click="closeSurvey();"></button>
            </div>
            <div class="c-main__container">
                <div class="c-intro" v-if="loader == false">
                    <h1 class="c-intro__title">{{text.title}}</h1>
                    <div class="c-intro__text">{{text.intro}}</div>
                </div>
                <div class="c-results" v-if="activePage == 'results'">
                    <div class="c-results__item" v-for="(result, index) in results" :key="index" >
                        <template v-for="output in result.questionnaireResultGroup.customFields" :key="output">
                            <h2 class="c-results__item-header" :data-title="output.translations[language].message" v-if="output.slug == 'name'">
                                {{text.step}}<span style="padding-left: 4px; padding-right: 4px;">{{index+1}} -</span>
                                {{output.translations[language].message}}
                            </h2>
                        </template>
                        <div class="c-results__item-content">
                            <template v-for="output in result.questionnaireResultOption.customFields" :key="output">
                                <div class="c-results__item-image" v-if="output.slug == 'product_img'">
                                    <img :src="output.translations[language].message" alt="">
                                </div>
                            </template>
                            <template v-for="output in result.questionnaireResultOption.customFields" :key="output">
                                <div class="c-results__item-overtitle" v-if="output.slug == 'product_title'">
                                    {{output.translations[language].message}}
                                </div>
                            </template>
                            <template v-for="output in result.questionnaireResultOption.customFields" :key="output">
                                <h3 class="c-results__item-title" v-if="output.slug == 'product_name'">
                                    {{output.translations[language].message}}
                                </h3>
                            </template>
                            <template v-for="output in result.questionnaireResultOption.customFields" :key="output">
                                <div class="c-results__item-text" v-if="output.slug == 'product_description'">
                                    {{output.translations[language].message}}
                                </div>
                            </template>
                            <template v-for="output in result.questionnaireResultOption.customFields" :key="output">
                                <a class="o-button o-button--no-margin" :href="output.translations[language].message" v-if="output.slug === 'product_url'" target="_blank" @click="trackProductClick(result.questionnaireResultOption.customFields)">
                                    <img class="o-button__img" :src="env.url + 'img/icon_search_white.svg'" alt="">
                                    <span class="o-button__text">{{text.showDetail}}</span>
                                </a>
                            </template>
                            <template v-for="output in result.questionnaireResultOption.customFields" :key="output">
                                <div class="c-results__item-tag" :href="output.translations[language].message" v-if="output.slug == 'product_tag' && output.translations[language]?.message">
                                    {{output.translations[language].message}}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="c-profile" v-if="activePage == 'profile'">
                    <div class="c-profile__verdict-container">
                        <div class="c-profile__verdict-image">
                            <img :src="text.profileImage" alt="">
                        </div>
                        <div class="c-profile__verdict-text">
                            {{text.profileText_4}}
                        </div>
                    </div>
                    <div class="c-profile__tips">
                        <div class="c-profile__tips-title">
                            {{text.expertTipTitle}}
                        </div>
                        <div class="c-profile__tips-text">
                            {{text.expertTip}}
                        </div>
                    </div>
                    <button class="c-profile__button o-button" @click="showProducts();">
                        <span class="o-button__text">{{text.showRoutine}}</span>
                        <img class="o-button__img" :src="env.url + 'img/icon_sipka_next.svg'" alt="">
                    </button>
                </div>
                <div class="c-recap" v-if="activePage == 'recap'">
                    <div class="c-recap__group-container">
                        <div class="c-recap__group">
                            <div class="c-recap__item" v-for="(answer, index) in answers" :key="index">
                                <div class="c-recap__item-header">
                                    <div class="c-recap__item-title">
                                        <span>{{index + 1}}.</span> {{answer.questionTitle}}
                                    </div>
                                    <button class="c-recap__item-edit-button" @click="editAnswer(answer.questionID, answer.groupID, answer.questionIndex, answer.questionCount);">{{text.edit}}</button>
                                </div>
                                <div class="c-recap__item-content">
                                    <div class="c-recap__item-image" v-if="answer.image">
                                        <img :src="answer.image" :alt="answer.option">
                                    </div>
                                    <div class="c-recap__item-text">{{answer.option}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="o-button-container o-button-container--always-fixed" v-if="activePage == 'recap'">
                        <button class="o-button" @click="validate(); trackCompleted();">
                            <span class="o-button__text">{{text.confirm}}</span>
                            <img class="o-button__img" :src="env.url + 'img/icon_sipka_next.svg'" alt="">
                        </button>
                    </div>
                </div>
                <div class="c-questions-container" :class="activePage == 'questions' ? 'c-questions-container--active' : ''">
                    <div class="c-group-navigation">
                        <button
                            class="c-group-navigation__item"
                            :key="index"
                            v-for="(value, index) in groupCount"
                            :class="groupActive >= index ? 'c-group-navigation__item--processed' : ''"
                            @click="goToGroup(index)">
                            {{value}}
                        </button>
                    </div>
                    <template v-for="(group, groupIndex) in groups" :key="groupIndex" >
                        <div class="c-question-group" :class="groupIndex == groupActive ? 'c-question-group--active' : ''">
                            <div class="c-question-group__slider" :style="{right:questionPosition}">
                                <div
                                    class="c-question"
                                    v-for="(question, questionIndex) in group.questions" :key="questionIndex"
                                    :class="[
                                        (questionActive == questionIndex ? 'c-question--active' : ''),
                                        (question.questionType == 1 ? 'c-question--type-A' : ''),
                                        (question.questionType == 2 ? 'c-question--type-B' : ''),
                                        (question.questionType == 3 ? 'c-question--type-C' : '')
                                        ]">
                                    <div class="c-question__header">
                                        <h2 class="c-question__title">
                                            <span>{{questionIndex + 1}}. </span>
                                            {{question.customFields[0].translations[language].message}}
                                        </h2>
                                        <button class="c-question__details-button" @click="detailID = question.id" v-if="question.customFields[2].translations[language].message == 1">
                                            {{text.more}}
                                        </button>
                                    </div>
                                    <div class="c-question__text">
                                        {{question.customFields[1].translations[language].message}}
                                    </div>
                                    <div class="c-question__options-container"
                                        :class="[
                                            (question.questionType == 1 ? 'c-question__options-container--type-A' : ''),
                                            (question.questionType == 2 ? 'c-question__options-container--type-B' : ''),
                                            (question.questionType == 3 ? 'c-question__options-container--type-C' : '')
                                        ]">
                                        <template v-for="(option, optionIndex) in question.questionOptions" :key="optionIndex">
                                            <input
                                                class="c-question__option-input"
                                                type="radio"
                                                :disabled="questionActive != questionIndex && windowWidth > 1023"
                                                :name="'question_' + question.id"
                                                :id="'question_' + question.id + '_option_' + optionIndex"
                                                @click="
                                                    saveAnswer(option.id, question.id, option.customFields[1].translations[language]?.message, option.customFields[0].translations[language].message, question.customFields[0].translations[language].message, groupIndex, questionIndex, questionCount, option.customFields, $event);
                                                    trackAnswer(question.id, question.customFields[0].translations[language].message, option.id, option.customFields[0].translations[language].message)
                                                "
                                                :class="[
                                                    (question.questionType == 1 ? 'c-question__option-input--type-A' : ''),
                                                    (question.questionType == 2 ? 'c-question__option-input--type-B' : ''),
                                                    (question.questionType == 3 ? 'c-question__option-input--type-C' : '')
                                                ]"
                                            >
                                            <label
                                                class="c-question__option"
                                                :for="'question_' + question.id + '_option_' + optionIndex"
                                                :class="[
                                                    (question.questionType == 1 ? 'c-question__option--type-A' : ''),
                                                    (question.questionType == 2 ? 'c-question__option--type-B' : ''),
                                                    (question.questionType == 3 ? 'c-question__option--type-C' : '')
                                                ]"
                                                >
                                                <template v-for="(output, key) in option.customFields" :key="key">
                                                    <template v-if="output.slug == 'question_option_img'">
                                                        <img v-for="image in output.translations" :key="image" class="c-question__option-image" :src="image.message" alt="" :class="(question.questionType == 3 ? 'c-question__option-image--type-C' : '')">
                                                    </template>
                                                    <span class="c-question__option-text" v-if="output.slug == 'question_option_title'">
                                                        {{output.translations[language].message}}
                                                    </span>
                                                </template>
                                            </label>
                                        </template>
                                    </div>
                                    <Transition name="question-detail">
                                        <div class="c-question__detail" v-if="detailID == question.id ">
                                            <div class="c-question__detail-bg" @click="detailID = ''"></div>
                                            <div class="c-question__detail-container">
                                                <button class="c-question__detail-close-button" @click="detailID = ''"></button>
                                                <div class="c-question__detail-header">
                                                    <h2 class="c-question__detail-title">{{question.customFields[0].translations[language].message}}</h2>
                                                    <div class="c-question__detail-intro">{{question.customFields[1].translations[language].message}}</div>
                                                </div>
                                                <div class="c-question__detail-item-container">
                                                    <div class="c-question__detail-item" v-for="(option, optionIndex) in question.questionOptions" :key="optionIndex">
                                                        <template v-for="(output, key) in option.customFields" :key="key">
                                                            <div class="c-question__detail-item-main-img" v-if="output.slug == 'question_option_main_img' && output.translations[language]?.message">
                                                                <img :src="output.translations[language].message" alt="">
                                                            </div>
                                                        </template>
                                                        <template v-for="(output, key) in option.customFields" :key="key">
                                                            <div class="c-question__detail-item-title" v-if="output.slug == 'question_option_title'">
                                                                {{output.translations[language].message}}
                                                            </div>
                                                        </template>
                                                        <div class="c-question__detail-item-info">
                                                            <template v-for="(output, key) in option.customFields" :key="key">
                                                                <div class="c-question__detail-item-thumbnail" v-if="output.slug == 'question_option_img' && output.translations[language]?.message">
                                                                    <img class="c-question__detail-thumbnail" :src="output.translations[language].message" alt="">
                                                                </div>
                                                            </template>
                                                            <template v-for="(output, key) in option.customFields" :key="key">
                                                                <div v-if="output.slug == 'question_option_description' && output.message != ''" class="c-question__detail-item-description">
                                                                    {{output.translations[language].message}}
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="c-question-navigation">
                        <button class="c-question-navigation__item"
                            v-for="(value,index) in questionCount"
                            :key="index"
                            :class="questionActive == index ? 'c-question-navigation__item--active' : ''"
                            @click="slideQuestion(index)">
                        </button>
                    </div>
                    <div class="o-button-container o-button-container--always-fixed" v-if="activePage != 'recap'">
                        <button
                            class="o-button"
                            :disabled="proceedToNextGroup == false"
                            v-if="saveIsActive == false"
                            @click="goToNextGroup()">
                            <span class="o-button__text">{{text.next}}</span>
                            <img class="o-button__img" :src="env.url + 'img/icon_sipka_next.svg'" alt="">
                        </button>
                        <button
                            class="o-button"
                            v-if="saveIsActive == true"
                            @click="showRecap()">
                            <span class="o-button__text">{{text.save}}</span>
                        </button>
                    </div>
                </div>
                <button class="c-reset-button" @click="resetSurvey()" v-if="loader == false" :class="activePage == 'profile' ? 'c-reset-button--animated' : ''">
                    {{text.start}}
                </button>
            </div>
        </main>
    </div>
</template>
<script>
import axios from 'axios'
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import { deviceType } from 'mobile-device-detect';

export default {
    name: 'App',
    data() {
        return {
            clientId: '',
            platform: '',
            activePage: 'questions',
            language: 'cs_CZ',
            instance: '',
            env: {
                url: 'https://kerastase-client.inveo.cz/',
                api: 'https://kerastase.inveo.cz',
                elastic: 'https://kerastase-elastic.inveo.dev'
            },
            results: [],
            endPointData: [],
            answers: [],
            groups: [],
            groupsDone: [],
            groupCount: 0,
            groupActive: 0,
            questionCount: 0,
            questionActive: 0,
            questionPosition: 0,
            proceedToNextGroup: false,
            reset: false,
            loader: false,
            writeLock: false,
            saveIsActive: false,
            detailID: '',
            windowWidth: window.innerWidth,
            text: {
                title: '',
                intro: '',
                next: '',
                back: '',
                start: '',
                confirm: '',
                more: '',
                edit: '',
                showRoutine: '',
                recapTitle: '',
                recapIntro: '',
                loaderIntro: '',
                loaderTitle: '',
                step: '',
                showDetail: '',
                save: '',
                expertTipTitle: '',
                expertTip: '',
                profileTitle: '',
                profileText: '',
                profileText_1: '',
                profileText_2: '',
                profileText_3: '',
                profileText_4: '',
                profileImage: '',
                routineTitle: '',
                routineText: ''
            }
        }
    },
    methods: {
        saveAnswer(optionID, questionID, image, option, questionTitle, groupID, questionIndex, questionCount, optionData, event) {
            let _this = this;
            if(this.questionActive < (this.questionCount - 1)) {
                setTimeout(function() {
                    _this.questionActive++
                    _this.questionPosition = 'calc(' + (_this.questionActive * 100 ) + '% + ' + (_this.questionActive * 64 ) + 'px'
                }, 30)
            }

            // PUSH INTO ANSWERS ARRAY

            if(this.windowWidth < 1023) {
                this.$el.parentElement.scrollBy({
                    top: event.target.closest('.c-question__options-container').closest('.c-question').clientHeight,
                    left: 0,
                    behavior : "smooth"
                })
            }

            if(optionID) {
                this.writeLock = false;

                // LOOK FOR DUPLICATE IDS AND REWRITE EXISTING ANSWERS

                for (let i = 0; i < this.answers.length; i++) {
                    if (this.answers[i].questionID == questionID) {
                        this.answers[i].optionID = optionID
                        this.answers[i].image = image
                        this.answers[i].option = option
                        this.writeLock = true
                    }
                }

                // IF REWRITING DONT PUSH NEW ANSWER

                if(this.writeLock == false) {
                    this.answers.push({optionID, questionID, option, image, questionTitle, groupID, questionIndex, questionCount});
                }

                // EXPERT TIPS AND OTHER MISC BEHAVIOUR
                if(optionData[5].translations[this.language]?.message ?? '' != '') {
                    this.text.expertTip = optionData[5].translations[this.language].message
                }

                if(questionID == 1) {
                    this.text.profileText_1 = option.toLowerCase();
                }

                if(questionID == 2) {
                    this.text.profileText_2 = option.toLowerCase();
                }

                if(questionID == 8 && optionData[6].translations[this.language].message != '') {
                    this.text.profileText_3 = optionData[6].translations[this.language].message.toLowerCase();
                }

                if(questionID == 7) {
                    this.text.profileText_4 = optionData[2].translations[this.language].message;
                    this.text.profileImage = image
                }

                let checkedRadios = 0;

                for (let i = 0; i < this.answers.length; i++) {
                    if(this.groupActive == this.answers[i].groupID) {
                        checkedRadios++;
                    }
                }

                if (this.questionCount == checkedRadios) {
                    this.proceedToNextGroup = true
                    this.groupsDone.push(this.groupActive)
                }
            }
        },
        slideQuestion(index) {
            this.questionPosition = 'calc(' + (index * 100 ) + '% + ' + (index * 64) + 'px'
            this.questionActive = index
        },
        goBack() {
            this.questionActive--
            this.questionPosition = 'calc(' + (this.questionActive * 100 ) + '% + ' + (this.questionActive * 64 ) + 'px'
        },
        editAnswer(questionID, groupID, questionIndex, questionCount) {
            this.activePage = 'questions'
            this.groupActive = groupID
            this.questionCount = questionCount
            this.slideQuestion(questionIndex)
            this.saveIsActive = true
        },
        showRecap() {
            this.saveIsActive = false
            this.activePage = 'recap'
        },
        resetSurvey() {
            this.reset = true
            this.questionActive = 0
            this.questionPosition = 0
            this.groupActive = 0
            this.proceedToNextGroup = false
            this.activePage = ''
            this.answers = []
            this.loader = true
            this.loadData()

            setTimeout(()=>{
                this.reset = false
                this.activePage = 'questions'
            }, 500);
        },
        closeSurvey() {
            document.querySelector('#survey-app').classList.remove('active')
            document.getElementsByTagName('body')[0].style.overflow = 'auto'
        },
        populateIntro(data) {
            for (let i = 0; i < data.length; i++) {
                if(data[i].slug == 'question_group_subtitle') {
                    this.text.title = data[i].translations[this.language].message
                }
                if(data[i].slug == 'question_group_title') {
                    this.text.intro = data[i].translations[this.language].message
                }
            }
        },
        goToNextGroup() {
            if(this.groupActive >= (this.groupCount - 1)) {
                this.activePage = 'recap'
                this.text.title = this.text.recapTitle
                this.text.intro = this.text.recapIntro
            } else {
                this.groupsDone.push(this.groupActive)
                this.groupActive++
                this.questionActive = 0
                this.questionPosition = 0
                this.questionCount = this.endPointData.questionsGroups[this.groupActive].questions.length
                this.proceedToNextGroup = false

                let intro = this.endPointData.questionsGroups[this.groupActive].customFields
                this.populateIntro(intro)
            }
            if(this.groupsDone.includes(this.groupActive)) {
                this.proceedToNextGroup = true
            }

            this.$el.parentElement.scrollTo({ top: 0, behavior: 'smooth' })
        },
        goToGroup(index) {
            if(index < this.groupActive) {
                this.groupActive = index
                this.questionCount = this.endPointData.questionsGroups[index].questions.length
                this.questionActive = 0
                this.questionPosition = 0
                this.proceedToNextGroup = true
                let intro = this.endPointData.questionsGroups[index].customFields
                this.populateIntro(intro)
                this.$el.parentElement.scrollTo({ top: 0, behavior: 'smooth' })
            }
        },
        sortByOrdering(a,b) {
            if ( a.created_at < b.created_at ){
                return -1;
            }
            if ( a.created_at > b.created_at ){
                return 1;
            }
            return 0;
        },
        validate() {
            let _this = this
            this.loader = true
            this.activePage = ''

            let answerMatrix = '';

            for (var i = 0; i < this.answers.length; i++) {
                answerMatrix = answerMatrix + 'questionOptionsId[]=' + this.answers[i].optionID + '&'
            }

            let query = this.env.api + '/api/v2/shop/questionnaire-results?' + answerMatrix + 'page=1&itemsPerPage=30&instance=' + this.instance

            axios
                .get(
                     query, {
                     headers: {
                       'Content-Type' : 'application/json',
                       'Accept' : 'application/json',
                       'Accept-Language': this.language
                     }})
                .then(response => {
                    this.results = response.data.sort((a,b) => (a.questionnaireResultGroup.customFields[1].translations[this.language].message > b.questionnaireResultGroup.customFields[1].translations[this.language].message) ? 1 : ((b.questionnaireResultGroup.customFields[1].translations[this.language].message > a.questionnaireResultGroup.customFields[1].translations[this.language].message) ? -1 : 0));                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setTimeout(() => {
                        _this.loader = false
                        _this.activePage = 'profile'
                        _this.text.title = _this.text.profileTitle
                        _this.text.profileText = _this.text.profileText.replace('[hair texture #1]', _this.text.profileText_1).replace('[hair type #2]', _this.text.profileText_2).replace("[hair secret's weapon #8]", _this.text.profileText_3)
                        _this.text.intro = _this.text.profileText
                    }, 3000);
                })
        },
        showProducts() {
            this.activePage = 'results'
            this.text.title = this.text.routineTitle
            this.text.intro = this.text.routineText

            this.trackResult();
        },
        loadData() {
            this.text.loaderTitle = ''
            this.text.loaderIntro = ''
            this.loader = true

            axios
                .get(
                    this.env.api + '/api/v2/shop/questionnaires?page=1&itemsPerPage=30', {
                     headers: {
                       'Content-Type' : 'application/json',
                       'Accept' : 'application/json',
                       'Accept-Language': this.language,
                    }})
                .then(response => {
                    this.endPointData = response.data[0]
                    this.groups = response.data[0].questionsGroups
                    this.groupCount = response.data[0].questionsGroups.length
                    this.questionCount = response.data[0].questionsGroups[0].questions.length

                    let intro = response.data[0].questionsGroups[0].customFields
                    this.populateIntro(intro)

                    // TRANSLATIONS

                    let translations = response.data[0].customFields

                    for (let i = 0; i < translations.length; i++) {
                        switch (translations[i].slug) {
                            case 'cta_start' : this.text.start = translations[i].translations[this.language].message; break;
                            case 'cta_next' : this.text.next = translations[i].translations[this.language].message; break;
                            case 'cta_confirm' : this.text.confirm = translations[i].translations[this.language].message; break;
                            case 'cta_show_rutine' : this.text.showRoutine = translations[i].translations[this.language].message; break;
                            case 'cta_save' : this.text.save = translations[i].translations[this.language].message; break;
                            case 'cta_edit' : this.text.edit = translations[i].translations[this.language].message; break;
                            case 'main_recapitulation' : this.text.recapTitle = translations[i].translations[this.language].message; break;
                            case 'recapitulation_description' : this.text.recapIntro = translations[i].translations[this.language].message; break;
                            case 'back_link' : this.text.back = translations[i].translations[this.language].message; break;
                            case 'more_link' : this.text.more = translations[i].translations[this.language].message; break;
                            case 'step' : this.text.step = translations[i].translations[this.language].message; break;
                            case 'show_detail' : this.text.showDetail = translations[i].translations[this.language].message; break;
                            case 'expert_tips' : this.text.expertTipTitle = translations[i].translations[this.language]?.message; break;
                            case 'profile_title' : this.text.profileTitle = translations[i].translations[this.language].message; break;
                            case 'profile_description' : this.text.profileText = translations[i].translations[this.language].message; break;
                            case 'my_routine' : this.text.routineTitle = translations[i].translations[this.language].message; break;
                            case 'my_routine_description' : this.text.routineText = translations[i].translations[this.language].message; break;
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    var _this = this;
                    setTimeout(function() {
                        _this.loader = false
                    }, 1700);
                    setTimeout(function() {
                        let translations = _this.endPointData.customFields

                        for (let i = 0; i < translations.length; i++) {
                            switch (translations[i].slug) {
                                case 'analyze_title' : _this.text.loaderTitle = translations[i].translations[_this.language].message; break;
                                case 'analyze_description' : _this.text.loaderIntro = translations[i].translations[_this.language].message; break;
                            }
                        }
                    }, 3000);
                })
        },
        trackVisitor() {
            const index = 'inveocz_survey_visitor';

            let data = {
                'client_id': this.clientId,
                'client_url': location.toString(),
                'user_id': this.getUserUuid(),
                'platform': this.platform,
                'created': dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
            };

            this.sendEvent(index, data);
        },
        trackAnswer(questionId, questionText, answerId, answerText) {
            const index = 'inveocz_survey_question';
            let data = {
                'question_id': questionId,
                'question_text': questionText,
                'answer_id': answerId,
                'answer_text': answerText,
                'client_id': this.clientId,
                'client_url': location.toString(),
                'user_id': this.getUserUuid(),
                'platform': this.platform,
                'created': dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
            }

            this.sendEvent(index, data);
        },
        trackResult() {
            const index = 'inveocz_survey_result';

            this.results.forEach((group) => {
                let title, subtitle, description, ean, url = '';

                group.questionnaireResultOption.customFields.forEach((value) => {
                    if (value.slug === 'product_title') {
                        title = value.translations[this.language].message;
                    }
                    if (value.slug === 'product_name') {
                        subtitle = value.translations[this.language].message;
                    }
                    if (value.slug === 'product_description') {
                        description = value.translations[this.language].message;
                    }
                    if (value.slug === 'ean_inter') {
                        ean = value.translations[this.language].message;
                    }
                    if (value.slug === 'product_url') {
                        url = value.translations[this.language].message;
                    }
                });

                let stepText = '';
                group.questionnaireResultGroup.customFields.forEach((value) => {
                    if (value.slug === 'name') {
                        stepText = value.translations[this.language].message;
                    }
                });

                let product = {
                    'title': title,
                    'subtitle': subtitle,
                    'description': description,
                    'ean': ean,
                    'url': url,
                    'step': stepText,
                    'client_id': this.clientId,
                    'client_url': location.toString(),
                    'user_id': this.getUserUuid(),
                    'platform': this.platform,
                    'created': dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
                };
                this.sendEvent(index, product);
            });

        },
        trackProductClick(productData) {
            const index = 'inveocz_survey_click_product';

            let title, subtitle, description, ean, url = '';
            productData.forEach((value) => {
                if (value.slug === 'product_title') {
                    title = value.translations[this.language].message;
                }
                if (value.slug === 'product_name') {
                    subtitle = value.translations[this.language].message;
                }
                if (value.slug === 'product_description') {
                    description = value.translations[this.language].message;
                }
                if (value.slug === 'ean_inter') {
                    ean = value.translations[this.language].message;
                }
                if (value.slug === 'product_url') {
                    url = value.translations[this.language].message;
                }
            });

            let product = {
                'title': title,
                'subtitle': subtitle,
                'description': description,
                'ean': ean,
                'url': url,
                'client_id': this.clientId,
                'client_url': location.toString(),
                'user_id': this.getUserUuid(),
                'platform': this.platform,
                'created': dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
            };

            this.sendEvent(index, product);
        },
        trackCompleted() {
            const index = 'inveocz_survey_completed';

            let data = {
                'client_id': this.clientId,
                'client_url': location.toString(),
                'user_id': this.getUserUuid(),
                'platform': this.platform,
                'created': dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
            };

            this.sendEvent(index, data);
        },
        async sendEvent(index, data) {
            await this.axios.post(
                this.env.elastic + '/' + index + '/_doc',
                data,
                {
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        "Authorization": "ApiKey UE5EMldZRUJFbXBkbGR4eFNBbUs6RjR4UlJZelJTMm1DSVBtcUlDaUw5dw=="
                    }
                }
            )
        },
        getUserUuid() {
            let uuid = localStorage.survey_uuid;
            if (uuid === undefined) {
               uuid = localStorage.survey_uuid = uuidv4() + '-' + dayjs().valueOf();
            }

            return uuid;
        }
    },
    mounted() {
        this.clientId = this.$el.parentElement.getAttribute('data-client-id');

        switch (this.clientId) {
            case 'PVSB-684K-KNXQ-V5S4':
                this.instance = 'notino';
                break;
            case 'I8T6-CBES-BAEK-F65F':
                this.instance = 'kadernictvi';
                break;
            case '3AMV-HDNH-52TP-PHTT':
                this.instance = 'douglas';
                break;
            case '82UG-NJZ7-1KTJ-WAVW':
                this.instance = 'notino';
                this.language = 'de_DE';
                break;
            case 'JTDD-53KI-UCT2-WV3S':
                this.instance = 'notino';
                this.language = 'it_IT';
                break;
            default:
                this.instance = 'xxx';
                this.language = 'cs_CZ';
        }

        this.loadData()

        var url_string = window.location.href;
        var url = new URL(url_string);
        this.platform = deviceType === undefined ? 'desktop' : deviceType;
        var open = url.searchParams.get("survey");

        if(open && open == '1') {
            this.$el.parentElement.classList.add('active');
        }

        this.trackVisitor();
    }
}
</script>
<style lang="scss">

    // ENVIRONMENT

    //$env: './assets/'; // DEV
    $env: ''; // PRODUCTION

    // FONTS

    @font-face {
        font-family: "Play Fair";
        src: url(#{$env}fonts/PlayfairDisplay-Regular.woff2) format("woff2"),
            url(#{$env}fonts/PlayfairDisplay-Regular.woff) format("woff"),
            url(#{$env}fonts/PlayfairDisplay-Regular.svg) format("svg"),
            url(#{$env}fonts/PlayfairDisplay-Regular.ttf) format("truetype");
    }

    @font-face {
        font-family: "Gotham";
        src: url(#{$env}fonts/Gotham-Book.otf) format("opentype");
    }

    @font-face {
        font-family: "Gotham";
        src: url(#{$env}fonts/Gotham-Light.otf) format("opentype");
        font-weight: 300;
    }


    // VARIABLES

    $primary-font         : 'Gotham', sans-serif;
    $secondary-font       : 'Play Fair', cursive;
    $border-color         : #D5D5D5;
    $base-color           : #161515;

    // COMPONENTS

    @import "./scss/_mixins.scss";
    @import "./scss/_reset.scss";
    @import "./scss/_default.scss";
    @import "./scss/_questions.scss";
    @import "./scss/_recap.scss";
    @import "./scss/_profile.scss";
    @import "./scss/_results.scss";

</style>
